import i18n from '../i18n'
import moment from 'moment'
import {
  ruleTypes,
  productEntities,
  voucherEntities,
  customerEntities,
  paymentMethodTypesArgExclusive,
  calculationTypes,
  calculateByTypes,
} from '../components/PromoWizard/Steps/ConditionsForm/variables'
import { actionTypes } from '../components/PromoWizard/Steps/ActionsForm/variables'
import { getEntities, getEntityNames } from '../api/EntitiesAPI'
import numberFormatter from './numberFormatter'

const { numberFormat, integer } = numberFormatter()

const daysIndex = {
  1: i18n.t('createPromo:days.monday'),
  2: i18n.t('createPromo:days.tuesday'),
  3: i18n.t('createPromo:days.wednesday'),
  4: i18n.t('createPromo:days.thursday'),
  5: i18n.t('createPromo:days.friday'),
  6: i18n.t('createPromo:days.saturday'),
  7: i18n.t('createPromo:days.sunday'),
}

export const parseActivation = act => {
  const date = act.date_setting
  const time_range = act.time_range
  let days
  if (date.hasOwnProperty('days') && date.days.length) {
    days =
      act.date_setting.days.length === 7
        ? i18n.t('createPromo:steps.when.form.everyday')
        : act.date_setting.days.map(d => daysIndex[d]).join(', ')
  }
  if (date.hasOwnProperty('day_month') && date.day_month > 0) {
    days = `${i18n.t('createPromo:steps.when.form.day')} ${date.day_month} ${i18n.t(
      'createPromo:steps.when.form.of_the_month'
    )}`
  }
  if (date.hasOwnProperty('specific_day') && date.specific_day) {
    days = moment(date.specific_day).format('DD/MM/YYYY')
  }

  const time = time_range.all_day
    ? i18n.t('createPromo:steps.when.form.all_day')
    : `${i18n.t('createPromo:steps.when.form.from')} ${time_range.time_from} ${i18n.t(
        'createPromo:steps.when.form.to_time'
      )} ${time_range.time_to}`

  return Promise.resolve({ days, time })
}

export const parseRule = rule => {
  // TODO: find a way to merge arrays of entities. Payment methods ('CREDIT_CARD', 'INTERDEPOSIT') won't show up here
  //  thus the 'or' operator.
  const ruleType = ruleTypes.find(r => r.entities.some(e => e.value === rule.entity)) || {
    name: i18n.t('createPromo:steps.conditions.form.voucher'),
  }
  i18n.t('createPromo:steps.conditions.form.product')
  let entityType = [...productEntities, ...voucherEntities, ...customerEntities].find(e => e.value === rule.entity) || {
    name: i18n.t('createPromo:steps.conditions.form.payment_method'),
  }
  const entities = rule.entity_details

  if (entityType.name === i18n.t('createPromo:steps.conditions.form.payment_method')) {
    entityType = paymentMethodTypesArgExclusive.find(p => p.value === rule.entity)
  }

  let info = [ruleType.name, i18n.t(entityType.name)]

  if (rule.hasOwnProperty('details')) {
    if (rule.details.hasOwnProperty('fees') && rule.details.fees.length) {
      info = [...info, i18n.t('createPromo:steps.conditions.form.fees'), rule.details.fees.join(', ')]
    } else if (rule.details.hasOwnProperty('calculation_type') && rule.details.calculation_type !== 'NONE') {
      let calculationInfo
      const calculateBy = rule.details.calculation_type.includes('QUANTITY') ? 'quantity' : 'price'
      calculationInfo = [
        calculateByTypes[calculateBy],
        calculationTypes[calculateBy].find(c => c.value === rule.details.calculation_type).name,
        rule.details.calculation_type.includes('RANGE')
          ? [
              `$${numberFormat.format(parseFloat(rule.details.range_from).toFixed(2))}`,
              `$${numberFormat.format(parseFloat(rule.details.range_to).toFixed(2))}`,
            ].join(` ${i18n.t('createPromo:steps.conditions.form.to')} `)
          : `${calculateBy === 'price' ? '$' : ''}${numberFormat.format(
              parseFloat(rule.details.range_from).toFixed(2)
            )}`,
      ]

      info = [...info, ...calculationInfo]
    }
  }

  if (rule?.exclusive) {
    info = [...info, 'Exclusivo']
  }

  if (rule?.excluded) {
    info = [...info, 'Excluido']
  }

  if (entities?.length) {
    return new Promise((res, rej) => {
      getEntityNames(
        entityType.endpoint,
        entities.map(entity => entity?.entity_id)
      ).then(({ data }) => {
        if (data?.length) {
          info.splice(
            2,
            0,
            data
              .map((e, index) => {
                if (entityType.endpoint === '/product') return e.id + ' - ' + e.description
                else {
                  if (entityType.endpoint === '/credit-card') {
                    let entitiesID = entities.find(element => element.entity_id === e.id)
                    let feesAvaible = e.fees.filter(fee => entitiesID.detail?.fees.includes(fee))
                    return `${e.description} | Cuotas: ${feesAvaible.join(', ')} `
                  } else return e.description
                }
              })
              .join(entityType.endpoint === '/credit-card' ? '| ' : ', ')
          )
        }
        res({ info: info.join(' | ') })
      })
    })
  }
  return Promise.resolve({ info: info.join(' | ') })
}

export const parseAction = action => {
  return new Promise(async (res, rej) => {
    const type = actionTypes.find(a => a.value === action.type)
    let entityType
    const { attributes, payments, max_discount } = action
    let arrayPromesa = []
    let creditNoteText = ''
    let arrayPromesaPayment = []
    let stringPayment = ''
    let discount = action.discount
      ? `${
          action.percentage
            ? numberFormat.format(parseFloat(action.discount)) + '%'
            : '$' + numberFormat.format(parseFloat(action.discount).toFixed(2))
        }`
      : null
    let applyTo = ''
    let creditNote = action.generate_credit_note

    if ((action.hasOwnProperty('quantity') && type.value === 'DISCOUNT') || type.value === 'SET_PRICE') {
      if (action?.lower_unit_price) applyTo = i18n.t('createPromo:steps.actions.form.lower_price')
      else if (action.quantity > 0) {
        applyTo = `${i18n.t('createPromo:steps.actions.form.apply_to')} ${integer.format(action.quantity)} ${i18n.t(
          'createPromo:steps.actions.form.units'
        )}`
      } else {
        applyTo = i18n.t('createPromo:steps.actions.form.apply_to_all_units')
      }
    }

    let info = [type.name]
    if (type.value !== 'SET_PRICE' && type.value !== 'DISCOUNT') info.push(discount)
    else {
      if (type.value === 'SET_PRICE') discount = numberFormat.format(parseFloat(action.discount).toFixed(2))
    }
    info.push(applyTo)

    if (creditNote && Array.isArray(action.action_line_entity)) {
      const entity = action.action_line_entity[0]?.entity_id

      if (entity !== undefined) {
        const entityNamesResponse = await getEntityNames('/product', [entity])
        if (entityNamesResponse.data) {
          let data = entityNamesResponse.data
          let entityString = 'Nota de Crédito: ' + data.map(e => e.id + ' - ' + e.description).join(', ')
          creditNoteText = entityString
        }
      }
    }

    if (payments && payments.length > 0) {
      payments.forEach(payment => {
        entityType = paymentMethodTypesArgExclusive.find(p => p.value === payment.type_entity_id)
        let entities = payment.entity_id ? payment.entity_id : []
        let type = i18n.t(entityType.name)
        if (entities?.length) {
          let promesa = getEntityNames(entityType.endpoint, entities).then(({ data }) => {
            if (data?.length) {
              let entityString = type + ': ' + data.map(e => e.description).join(', ')
              if (payment?.fees && payment.fees.length > 0) {
                entityString += ', Cuotas: ' + payment?.fees.map(e => e).join(', ')
              }
              stringPayment += entityString + '. '
            }
          })
          arrayPromesaPayment.push(promesa)
          arrayPromesa.push(promesa)
        }
      })
    } else if (type.value !== 'PRODUCT_BONUS') {
      stringPayment += 'Todos los medios de pago'
    }

    if (arrayPromesaPayment.length > 0) {
      Promise.all(arrayPromesaPayment)
        .then(values => {})
        .catch(reason => {
          console.log(reason)
        })
        .finally(() => {
          info.push(stringPayment)
        })
    } else info.push(stringPayment)

    if (attributes && attributes.length > 0) {
      attributes.forEach(attribute => {
        entityType = productEntities.find(a => a.value === attribute.entity)
        if (attribute.ids?.length) {
          arrayPromesa.push(
            getEntityNames(entityType.endpoint, attribute.ids).then(({ data }) => {
              let entityString =
                i18n.t(productEntities.find(a => a.value === attribute.entity).name) +
                ': ' +
                data
                  .map(e => (entityType.endpoint != '/product' ? e.description : e.id + ' - ' + e.description))
                  .join(', ')
              info.push(entityString)
            })
          )
        }
      })
    }
    if (arrayPromesa.length > 0) {
      Promise.all(arrayPromesa)
        .then(values => {})
        .catch(reason => {
          console.log(reason)
        })
        .finally(() => {
          if (attributes?.length > 0) info.push(discount)
          if (max_discount && max_discount > 0)
            info.push('Tope: $' + numberFormat.format(parseFloat(max_discount).toFixed(2)))
          if (creditNoteText !== '') info.push(creditNoteText)
          res({ info: info.filter(Boolean).join(' | ') })
        })
    } else {
      if (max_discount && max_discount > 0)
        info.push('Tope: $' + numberFormat.format(parseFloat(max_discount).toFixed(2)))
      if (creditNoteText !== '') info.push(creditNoteText)
      res({ info: info.filter(Boolean).join(' | ') })
    }
  })
}

export const parseOrigins = origins => {
  return new Promise((res, rej) => {
    getEntities('/origin').then(({ data: originTree }) => {
      let foundOrigins = []
      const findOrigins = obj => {
        if (origins.includes(obj.id)) {
          foundOrigins.push(obj.description)
        }
        if (!obj.childs) {
          return
        }
        obj.childs.forEach(child => findOrigins(child))
      }
      findOrigins(originTree)
      res({ info: foundOrigins.join(' | ') })
    })
  })

  // return Promise.resolve({info: origins.join(', ')})
}
